import { identity, pickBy } from "lodash-es";
import { Observable } from "rxjs";

import { IAutoExternalSourcingRequestDTO, IAutoExternalSourcingResponse } from "./DTOs/AutoExternalSourcingDTO";
import {
  ConnectionStatus,
  IApprovalRequestSavedResponse,
  ICortanaEvaluateDeleteUser,
  ICortanaOpportunity,
  ICortanaRequest,
  ICortanaRequestAndReview,
  ICortanaReviewRequest,
  ICortanaSaveTask,
  ICortanaUpdateRequest,
  IManualInviteSourcingCandidatesDTO,
  IReviewApprovalRequestResponse,
  IUpdateConnectionStatusDTO
} from "./DTOs/CortanaDTO";
import { IDataPointFIlterDTO, ISaveDataPointDTO, PaginatedDataPointsDTO } from "./DTOs/DataPointDTO";
import { IExternalSourcingRequestResultDTO, ISaveExternalSourcingRequestDTO } from "./DTOs/ExternalSourcingDTO";
import { ICreatedID, IMessageResponse } from "./DTOs/Misc";
import { ICandidateTestsEvaluations } from "./DTOs/Opportunity";
import {
  FindOpportunityApprovalRequestLogQuery,
  PaginatedOpportunityApprovalRequestLogDTO
} from "./DTOs/OpportunityApprovalRequest";
import { IPartnershipDTO } from "./DTOs/Partnership";
import { ISocialMediaCampaignTitles } from "./DTOs/SocialMediaCampaignDTO";
import {
  ICompleteTaskDTO,
  IFindTaskPropertyByTaskTriggerDTO,
  IFindTaskQuery,
  INotExecuteTaskDTO,
  IReassignTaskDTO,
  ISaveTaskPropertiesDTO,
  ISaveTaskResponse,
  ITaskPropertiesDTO,
  IUpdateTaskPropertiesDTO,
  PaginatedTasksDTO
} from "./DTOs/TaskDTO";
import { ITest, ITestResult, IUploadTestResultResponse } from "./DTOs/TestDTO";
import HTTPService from "./HTTPService";
import { ISavedAttachmentDTO } from "@/services/DTOs/Attachment";

class Service extends HTTPService {
  public getOpportunity(data: { opportunityId: string }): Observable<ICortanaOpportunity> {
    return this.get$<ICortanaOpportunity>(`/api/opportunities/${data.opportunityId}`);
  };

  public createOpportunity(data: object): Observable<ICreatedID> {
    return this.put$<ICreatedID>("/api/opportunities", data);
  }

  public getRequest(opportunityId: string): Observable<ICortanaRequestAndReview> {
    return this.get$<ICortanaRequestAndReview>(`/api/approval-request/${opportunityId}`);
  }

  public createRequest(opportunityId: string, request: ICortanaRequest): Observable<IApprovalRequestSavedResponse> {
    return this.post$<IApprovalRequestSavedResponse>(`/api/approval-request/add/${opportunityId}`, request);
  }

  public reviewRequest(opportunityId: string, request: ICortanaReviewRequest): Observable<IReviewApprovalRequestResponse> {
    return this.post$<IReviewApprovalRequestResponse>(`/api/approval-request/review/${opportunityId}`, request);
  }

  public updateRequest(opportunityId: string, request: ICortanaUpdateRequest): Observable<IMessageResponse> {
    return this.post$<IMessageResponse>(`/api/approval-request/update/${opportunityId}`, request);
  }

  public getApprovalRequestLogs(data: FindOpportunityApprovalRequestLogQuery): Observable<PaginatedOpportunityApprovalRequestLogDTO> {
    const { findQuery, ...pagination } = data;

    return this.post$<PaginatedOpportunityApprovalRequestLogDTO>(
      "/api/approval-request/logs",
      pickBy({ findQuery }, q => identity(q)),
      pagination
    );
  }

  public getTasks(query: IFindTaskQuery): Observable<PaginatedTasksDTO> {
    const { findQuery, ...pagination } = query;

    return this.post$<PaginatedTasksDTO>("/api/task/find", findQuery, pagination);
  }

  public completeTask(data: ICompleteTaskDTO): Observable<IMessageResponse> {
    return this.post$<IMessageResponse>(`/api/task/complete/${data.taskId}`, data);
  }

  public completeTaskWithoutExecution(data: INotExecuteTaskDTO): Observable<any> {
    return this.post$(`/api/task/complete-task-without-execution/${data.completeTaskDTO.taskId}`, data);
  }

  public saveSQs(opportunityHashId: string, data: object[]): Observable<number[]> {
    return this.post$<number[]>(`/api/screening-question/add/${opportunityHashId}`, data);
  }

  public getActiveTests(): Observable<ITest[]> {
    return this.get$<ITest[]>("/api/tests");
  }

  public uploadResult(data: ITestResult): Observable<IUploadTestResultResponse> {
    return this.put$<IUploadTestResultResponse>("/api/tests/result", data);
  }

  public fetchOpportunityColumnCandidatesSkillEvaluationSummaries(
    opportunityId: string,
    columnId: string
  ): Observable<ICandidateTestsEvaluations[]> {
    return this.get$<ICandidateTestsEvaluations[]>(`/api/opportunities/${opportunityId}/columns/${columnId}/skill-evaluations`);
  };

  public createTask(data: ICortanaSaveTask): Observable<ISaveTaskResponse> {
    return this.post$<ISaveTaskResponse>("/api/task", data);
  };

  public setTaskAsInProgress(taskId: number): Observable<void> {
    return this.put$(`/api/task/${taskId}/in-progress`, {});
  }

  public saveDataPoint(data: ISaveDataPointDTO): Observable<ICreatedID> {
    return this.post$<ICreatedID>("/api/data-point", data);
  };

  public fetchCheckpoints(page: number, pageSize: number, filter: IDataPointFIlterDTO): Observable<PaginatedDataPointsDTO> {
    return this.put$<PaginatedDataPointsDTO>("/api/data-points/find", filter, { page, pageSize });
  }

  public updateOpportunityMemberVisibility(opportunityMemberHashid: string, visibility: boolean): Observable<{
    opportunityMemberHashid: string;
  }> {
    return this.post$<{
      opportunityMemberHashid: string;
    }>(`/api/external-opportunity-members/${opportunityMemberHashid}/visibility/${visibility}`, {});
  }

  public getTaskProperties(params?: string): Observable<ITaskPropertiesDTO[]> {
    const urlParams = params !== undefined
      ? { "task-properties": params }
      : {};

    return this.get$<ITaskPropertiesDTO[]>("/api/task-properties", urlParams);
  }

  public findTaskPropertiesByTrigger(query: IFindTaskPropertyByTaskTriggerDTO): Observable<ITaskPropertiesDTO> {
    return this.post$<ITaskPropertiesDTO>("/api/task-properties/_search", query);
  }

  public saveTaskProperties(payload: ISaveTaskPropertiesDTO): Observable<ICreatedID> {
    return this.post$<ICreatedID>("/api/task-properties", payload);
  }

  public updateTaskProperties(id: number, payload: IUpdateTaskPropertiesDTO): Observable<void> {
    return this.put$(`/api/task-properties/${id}`, payload);
  }

  public toggleUnshipTaskProperties(id: number, unshipped: boolean): Observable<void> {
    return this.patch$(`/api/task-properties/${id}/unshipped/${unshipped}`, {});
  }

  public reassignTask(taskId: number, data: IReassignTaskDTO): Observable<void> {
    return this.put$(`/api/tasks/${taskId}/reassign`, data);
  }

  public uploadAttachment(attachment: File): Observable<ISavedAttachmentDTO> {
    const formData = new FormData();

    formData.append("file", attachment);
    return this.post$<ISavedAttachmentDTO>("/api/attachments", formData);
  }

  public deleteAttachmentBySourceKey(sourceKey: string): Observable<void> {
    return this.delete$(`/api/attachments/${sourceKey}`);
  }

  public getAllPartnerships(): Observable<IPartnershipDTO[]> {
    return this.get$<IPartnershipDTO[]>("/api/partnerships");
  }

  public evaluateUserDeletionStatus(data: ICortanaEvaluateDeleteUser): Observable<boolean> {
    return this.post$<boolean>("/api/people/user-deleted", data);
  }

  public updateConnectionStatus(username: string, status: ConnectionStatus, data: IUpdateConnectionStatusDTO): Observable<void> {
    return this.put$<void>(`/api/linkedin-connections/${username}/status/${status}`, data);
  }

  public createExternalSourcingRequest(data: ISaveExternalSourcingRequestDTO): Observable<IExternalSourcingRequestResultDTO> {
    return this.post$<IExternalSourcingRequestResultDTO>("/api/external-sourcing/find-candidates", data);
  }

  public createAutoExternalSourcingRequest(data: IAutoExternalSourcingRequestDTO): Observable<IAutoExternalSourcingResponse> {
    return this.post$<IAutoExternalSourcingResponse>("/api/recruiter-bot/sourcing-request", data);
  }

  public inviteSourcingCandidates(data: IManualInviteSourcingCandidatesDTO): Observable<void> {
    return this.post$<void>("/api/external-sourcing/invite-candidates", data);
  }

  public updateSuccessFee(opportunityId: string, successFee: number): Observable<void> {
    return this.put$<void>(`/api/opportunities/${opportunityId}/success-fee`, { successFee });
  }

  public createSocialMediaCampaignTitlesForMeta(opportunityId: string): Observable<ISocialMediaCampaignTitles> {
    return this.post$<ISocialMediaCampaignTitles>(
      "/api/social-media-campaign",
      {
        opportunityId,
        platform: "meta"
      }
    );
  }
}

export const CortanaService = new Service();
